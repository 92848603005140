<template>
    <BaseControl :configurable="!hideSettings" v-bind="attrs" :errors="combinedErrors" ref="base">
        <b-input-group>
            <input
                @keydown.enter="onEnterPressed"
                :value.sync="maskedValue"
                @input="onInput($event.target.value)"
                :class="classes"
                :type="type"
                class="form-control"
                :autocomplete="$attrs.autocomplete || 'none'"
                :id="id"
                :placeholder="attrs.label || placeholder"
            />

            <b-input-group-append>
                <b-button v-if="allowClear && maskedValue" variant="text" @click="handleClear">
                    <i class="fa fa-times"></i>
                </b-button>
                <b-button variant="primary" @click="onAction"> <i :class="buttonIcon"></i> {{ buttonText }} </b-button>
            </b-input-group-append>
        </b-input-group>
    </BaseControl>
</template>

<script>
import BaseControl from "@Platon/components/form/controls/BaseControl.vue"
import InputControlMixin from "@Platon/mixins/InputControlMixin"
import IMask from "imask"
import ValidationMixin from "@Platon/mixins/ValidationMixin"
import VueSimpleSuggest from "vue-simple-suggest"
import Btn from "@Platon/components/extended/Btn.vue"

export default {
    name: "TextFilter",
    components: { Btn, BaseControl, VueSimpleSuggest },

    mixins: [InputControlMixin, ValidationMixin],

    props: {},

    inject: {
        hideSettings: {
            default: false
        }
    },

    data() {
        return {
            maskedValue: null,
            mask: null,
            maskFilled: false,
            data: this.item && this.item.data,
            buttonIcon: "fa fa-search",
            buttonText: "",
            buttonOnClick: null,
            onEnter: null,
            autoCompleteStyle: {
                vueSimpleSuggest: "position-relative flex-grow-1",
                inputWrapper: "",
                defaultInput: "form-control",
                suggestions: "position-absolute list-group",
                suggestItem: "list-group-item"
            }
        }
    },

    beforeMount() {
        if (this.attrs.mask) {
            this.mask = IMask.createMask({
                mask: this.attrs.mask
            })
        }
    },

    mounted() {
        this.maskedValue = this.local
        if (this.mask) {
            this.$watch(
                "local",
                (val, old) => {
                    if (val && this.mask) {
                        this.updateMaskValue(val)
                    }
                },
                {
                    immediate: true
                }
            )
        }

        this.$refs.base.$el.querySelector("input").setAttribute("autocomplete", this.$attrs.autocomplete || "list")
    },

    methods: {
        handleClear() {
            this.updateLocal(null)
            this.maskedValue = null
        },
        onInput(value) {
            this.markAsDirty()

            if (this.mask) {
                this.updateMaskValue(value)
            } else {
                this.maskedValue = value
                // this.updateLocal(value)
            }
        },

        onAction() {
            if (typeof this.buttonOnClick === "function") {
                this.buttonOnClick(this.maskedValue)
                return
            }

            this.updateLocal(this.maskedValue)
        },

        updateMaskValue(value) {
            this.maskFilled = !this.mask.isComplete

            this.mask.resolve(value.toString())
            this.maskedValue = this.mask.value

            // this.updateLocal(this.mask.unmaskedValue)
            this.$nextTick(() => {
                this.$forceUpdate()
            })
        },

        onValueUpdate(val, old) {
            if (this.mask) {
                if (val && this.mask) {
                    this.updateMaskValue(val)
                }
            }
        },

        onEnterPressed(event) {
            let prevent = false

            try {
                if (this.onEnter && typeof this.onEnter === "function") {
                    prevent = this.onEnter(this.maskedValue, event)
                }
            } catch (e) {
                console.warn(e)
            }

            if (prevent === true) {
                return
            }

            this.updateLocal(this.maskedValue)
        }
    },

    computed: {
        classes() {
            return [`text-${this.attrs.text_align || "left"}`]
        },
        allowClear() {
            if (typeof this.item.allowClear === "boolean") {
                return this.item.allowClear
            }
            return true
        },

        type() {
            return this.attrs.type || "text"
        }
    }
}
</script>

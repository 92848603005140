<template>
    <div v-if="hasItems">
        <component
            v-if="shouldDisplay(e)"
            :is="e.__type"
            :key="`${position}-left-${e.__type}-${e.id}`"
            v-for="(e, i) in elements.left"
            :item="e"
            :table="table"
        />

        <div class="mx-auto"></div>

        <component
            v-if="shouldDisplay(e)"
            :is="e.__type"
            :key="`${position}-right-${e.__type}-${e.id}`"
            v-for="(e, i) in elements.right"
            :item="e"
            :table="table"
        />
    </div>
</template>

<script>
import TableButton from "@Platon/components/table/filters/TableButton.vue"
import PlatonFilterElement from "@Platon/components/table/filters/PlatonFilterElement.vue"
import { runScopeFn } from "@Platon/core/condition"

export default {
    name: "TableFilterBar",

    components: {
        TableButton,
        TableFilter: PlatonFilterElement
    },

    props: {
        position: {
            required: true,
            type: String
        },

        itemGroups: {
            type: Object,
            required: true
        },

        table: {
            type: Object,
            required: true
        },

        hideSettings: {
            type: Boolean,
            default: false
        }
    },

    provide() {
        return {
            table: this.table,
            hideSettings: this.hideSettings
        }
    },

    methods: {
        collectAndSort(leftOrRight) {
            const elements = []

            if (Array.isArray(this.itemGroups[`${this.position}-${leftOrRight}`])) {
                elements.push(...this.itemGroups[`${this.position}-${leftOrRight}`])
            }

            // sort buttons and filters by sortOrder
            elements.sort((a, b) => {
                return a.sortOrder - b.sortOrder
            })

            return elements
        },

        shouldDisplay(element) {
            if (!element.displayCondition) return true

            if (typeof element.displayCondition === "function") {
                return element.displayCondition.call(this, this.table)
            } else if (typeof element.displayCondition === "string") {
                return runScopeFn(element.displayCondition, this, { table: this.table })
            }
        }
    },

    computed: {
        elements() {
            return {
                left: this.collectAndSort("left"),
                right: this.collectAndSort("right")
            }
        },

        hasItems() {
            return this.elements.left.length + this.elements.right.length > 0
        }
    }
}
</script>

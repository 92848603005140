<template>
    <b-breadcrumb v-if="breadcrumbs && breadcrumbs.items && breadcrumbs.items.length > 0" class="mb-0 py-1">
        <li v-for="(item, index) in breadcrumbs.items" :key="index" class="breadcrumb-item">
            <span v-if="index + 1 === breadcrumbs.items.length">{{ $l(item.translate, item.text) }}</span>

            <platon-link v-else :link="item.link">{{ $l(item.translate, item.text) }}</platon-link>

            <table-breadcrumb-dropdown :breadcrumbs="breadcrumbs" :index="index" />
        </li>
    </b-breadcrumb>
</template>

<script>
import TableBreadcrumbDropdown from "@Platon/components/table/TableBreadcrumbDropdown"
export default {
    name: "TableBreadcrumb",
    components: { TableBreadcrumbDropdown },
    props: {
        endpoint: {},

        filters: {}
    },

    data() {
        return {
            breadcrumbs: []
        }
    },

    created() {
        $api.get(this.endpoint, {
            params: this.filters
        }).then(({ data }) => {
            this.breadcrumbs = data.data
        })
    }
}
</script>

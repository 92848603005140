<template>
    <component
        :is="`${item.type}-control`"
        ref="element"
        element-type="table_filters"
        :element-id="item.id"
        :value="local"
        :class="item.cssClass"
        :style="item.cssStyle"
        @input="onFilterUpdate"
        v-bind="extraOptions"
        :item="item"
        :key="`e-${item.id}`"
    />
</template>

<script>
import TextControl from "./TextFilter.vue"
import SelectControl from "@Platon/components/form/controls/SelectControl.vue"
import CheckboxControl from "@Platon/components/form/controls/CheckboxControl.vue"
import DateControl from "@Platon/components/form/controls/DateControl.vue"
import DatetimeControl from "@Platon/components/form/controls/DatetimeControl.vue"
import TimeControl from "@Platon/components/form/controls/TimeControl.vue"
import NumberControl from "@Platon/components/form/controls/NumberControl.vue"
import TableFilterDateRangePicker from "@Platon/components/table/filters/TableFilterDateRangePicker.vue"
import { or } from "@Platon/core/helpers"
import { DEV_MODE } from "@Platon/const"
import TreeSelectFilter from "@Platon/components/table/filters/TreeSelectFilter.vue"

export default {
    name: "PlatonFilterElement",

    components: {
        DateControl,
        DatetimeControl,
        CheckboxControl,
        SelectControl,
        TextControl,
        NumberControl,
        TimeControl,
        DaterangeControl: TableFilterDateRangePicker,
        treeselectControl: TreeSelectFilter
    },

    props: {
        /** @type PlatonFormElement */
        item: {},

        table: {
            type: Object,
            required: true
        }
    },

    data() {
        let local = or(this.table.filterData[this.item.key], or(this.item.defaultValue, null))

        return {
            local: local,
            startTime: new Date()
        }
    },

    created() {
        if (this.item.key) {
            this.table.filterElements.set(this.item.key, this)

            if (DEV_MODE) console.log(`Mounting ${this.item.key} to ${this.table.tableName}`)
        }
    },

    mounted() {
        if (this.table) {
            this.$watch(`table.filterData.${this.item.key}`, (newVal, old) => {
                this.local = newVal
            })
        }
    },

    beforeDestroy() {
        if (this.item.key) this.table.filterElements.delete(this.item.key)
    },

    methods: {
        onFilterUpdate(value) {
            if (new Date().getTime() - this.startTime.getTime() >= 1000) this.table.resetPage()

            this.table.setFilter(this.item.key, value)
        }
    },

    computed: {
        extraOptions() {
            let attrs = this.item.attributes || {}

            attrs = {
                class: "align-self-end",
                ...attrs
            }

            if (this.item.type === "select") {
                attrs.placeholder = this.item.title
                // attrs.label = null
                attrs.label = this.item.title
            } else if (this.item.type === "date") {
                attrs.label = this.item.title
                // options.label = null
            } else {
                attrs.label = this.item.title
            }

            if (this.item.type === "date" || this.item.type === "datetime") {
                attrs["hasControls"] = true
                attrs["text_align"] = "center"
            }

            attrs.size = this.item.width || 2
            attrs.defaultValue = this.item.defaultValue
            attrs.disableLabel = !attrs.has_label

            return attrs
        }
    }
}
</script>

<style></style>

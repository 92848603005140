<template>
    <div>
        <portal v-if="active && showFilters" to="topbar-filters" class="px-2" style="flex-grow: 100">
            <TableFilterBar
                :hide-settings="true"
                key="topbar-filters"
                position="topbar"
                :item-groups="groupedFilters"
                :table="table"
                class="form-row table-filters px-2"
            />
        </portal>

        <TableFilterBar
            v-if="showFilters"
            key="top-filters"
            position="top"
            :item-groups="groupedFilters"
            :table="table"
            class="form-row mx-0 py-1 table-filters"
        />

        <div>
            <slot name="title"></slot>
        </div>

        <TableFilterBar
            v-if="showFilters"
            key="top-after-filters"
            position="after-top"
            :item-groups="groupedFilters"
            :table="table"
            class="form-row mx-0 py-1 table-filters"
        />

        <slot></slot>

        <TableFilterBar
            v-if="showFilters"
            key="bottom-filters"
            position="bottom"
            :item-groups="groupedFilters"
            class="form-row mx-0 py-1 table-filters"
            :table="table"
        />
    </div>
</template>

<script>
import TableFilterBar from "@Platon/components/table/filters/TableFilterBar.vue"
import groupBy from "lodash.groupby"
import { DEV_MODE } from "@Platon/const"

export default {
    name: "TableFilterWrapper",
    components: { TableFilterBar },

    props: {
        showFilters: {
            type: Boolean,
            default: true
        },

        items: {
            type: Array,
            required: true
        },

        value: {
            type: Object
        }
    },

    inject: ["table"],

    computed: {
        groupedFilters() {
            return groupBy(this.items, "position")
        }
    },

    mounted() {
        if (DEV_MODE) console.log(`Mounting ${this.table.tableName}`)
    },

    data() {
        return {
            active: true
        }
    },

    activated() {
        this.active = true
    },

    deactivated() {
        this.active = false
    },

    methods: {
        setFilter() {}
    }
}
</script>
